<template>
    <div>
        <div class="form-search-box flex space">
            <div class="flex alcenter">
                <a-button @click="addCustom" type="primary">添加客户</a-button>
            </div>
            <div>
                <a-form layout="inline">
                    <a-form-item label="客户状态">
                        <a-select v-model="search.status" style="width: 90px">
                            <a-select-option :value="0">未选择</a-select-option>
                            <a-select-option :value="1">跟踪中</a-select-option>
                            <a-select-option :value="2">已成交</a-select-option>
                            <a-select-option :value="3">已流失</a-select-option>
                            <a-select-option :value="4">已退款</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="日期范围">
                        <a-range-picker v-model="search.data" valueFormat="YYYY-MM-DD" style="width: 200px;" />
                    </a-form-item>
                    <a-form-item label="搜索">
                        <a-input v-model="search.keyword" style="width: 120px"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="mt20 pd20 bg-w">
            <div class="xhm-table-gray mt20">
                <a-table :columns="columns" :pagination="pagination" @change="change" :data-source="list"
                    :loading="loading" rowKey="custom_id" tableLayout="auto">
                    <!-- 来源/名字 -->
                    <template slot="source" slot-scope="record,index">
                        <div>
                            <a-tag color="orange" v-if="record.source == 1">淘宝</a-tag>
                            <a-tag color="red" v-if="record.source == 2">小红书</a-tag>
                            <a-tag color="green" v-if="record.source == 3">视频号</a-tag>
                            <a-tag color="green" v-if="record.source == 4">裂变</a-tag>
                            <a-tag color="purple" v-if="record.source == 5">其他</a-tag>
                        </div>
                        <div class="mt5">{{ record.name }}</div>
                    </template>
                    <!-- 产品 -->
                    <template slot="product" slot-scope="record,index">
                        <a-tag color="green" v-if="record.product == 1">小程序</a-tag>
                        <a-tag color="cyan" v-if="record.product == 2">图片</a-tag>
                        <a-tag color="blue" v-if="record.product == 3">其他</a-tag>
                    </template>
                    <!-- 类型 -->
                    <template slot="type" slot-scope="record,index">
                        <a-tag color="#87d068" v-if="record.type == 1">标准版</a-tag>
                        <a-tag color="#2db7f5" v-if="record.type == 2">企业版</a-tag>
                        <a-tag color="#f50" v-if="record.type == 3">图片版</a-tag>
                    </template>
                    <!-- 类型 -->
                    <template slot="weixin" slot-scope="record,index">
                        <div>{{ record.weixin }}</div>
                        <div class="mt5">{{ record.mobile }}</div>
                    </template>
                    <!-- 状态 -->
                    <template slot="status" slot-scope="record,index">
                        <a-tag color="red" v-if="record.status == 1">跟踪中</a-tag>
                        <a-tag color="green" v-if="record.status == 2">已成交</a-tag>
                        <a-tag color="orange" v-if="record.status == 3">已流失</a-tag>
                        <a-tag color="pink" v-if="record.status == 4">已退款</a-tag>
                        <div v-if="record.status == 1" class="mt5">
                            <a-tag color="orange" @click="loss(record)">流失</a-tag> <a-tag color="#f50"
                                @click="follow(record)">跟进</a-tag>
                            <div class="mt5">{{ record.track_time }}</div>
                        </div>
                    </template>
                    <template slot="action" slot-scope="record,index">
                        <a-button type="primary" shape="circle" icon="edit" @click="editCustom(record)" />
                        <orderDanger :detail="record" class="mt5"></orderDanger>
                    </template>
                </a-table>
            </div>
        </div>
        <a-modal width="540px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <div class="flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 80px;">登记日期：</div>
                    <a-date-picker v-model="customData.consult" />
                </div>
                <div style="width: 235px;" class="ml20 flex alcenter">
                    <div style="width: 80px;">客户来源：</div>
                    <a-select v-model="customData.source" style="width: 155px;">
                        <a-select-option :value="1">淘宝</a-select-option>
                        <a-select-option :value="2">小红书</a-select-option>
                        <a-select-option :value="3">视频号</a-select-option>
                        <a-select-option :value="4">裂变</a-select-option>
                        <a-select-option :value="5">其他</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 80px;">客户类型：</div>
                    <a-radio-group v-model="customData.type" button-style="solid" style="width: 155px;">
                        <a-radio-button :value="1">标</a-radio-button>
                        <a-radio-button :value="2">企</a-radio-button>
                        <a-radio-button :value="3">图</a-radio-button>
                    </a-radio-group>
                </div>

                <div style="width: 235px;" class="ml20 flex alcenter">
                    <div style="width: 80px;">咨询产品：</div>
                    <a-select v-model="customData.product" style="width: 155px;">
                        <a-select-option :value="1">名片</a-select-option>
                        <a-select-option :value="2">小红书</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="flex mt10">
                <a-input style="width: 235px;" addon-before="名字" v-model="customData.name" />
                <a-input class="ml20" style="width: 235px;" addon-before="手机号" v-model="customData.mobile" />
            </div>
            <div class="flex mt10">
                <a-input style="width: 235px;" addon-before="微信" v-model="customData.weixin" />
                <div style="width: 235px;" class="ml20 flex alcenter">
                    <div style="width: 80px;">客户状态：</div>
                    <a-select v-model="customData.status" style="width: 155px;">
                        <a-select-option :value="0">未选择</a-select-option>
                        <a-select-option :value="1">跟踪中</a-select-option>
                        <a-select-option :value="2">已成交</a-select-option>
                        <a-select-option :value="3">已流失</a-select-option>
                        <a-select-option :value="4">已退款</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="flex mt10">
                <a-input style="width: 235px;" addon-before="排序" v-model="customData.sort" type="number" />
            </div>
            
            <div class="mt12 flex alcenter">
                <div style="width: 100px;">备注：</div>
                <a-textarea v-model="customData.detail" :rows="4" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { apiCustomSave, apiCustomList, apiFollow } from '../../utils/js/api';
import orderDanger from './danger.vue';
export default {
    components: {
        orderDanger
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keyword: '',
                status: 0,
                data: ['', '']
            },
            show: false,
            title: '添加客户',
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '客户id', dataIndex: 'custom_id', align: 'left' },
                { title: '咨询日期', dataIndex: 'consult', align: 'left' },
                { title: '类型', key: 'type', align: 'left', scopedSlots: { customRender: 'type' }, filters: [{ text: '个人', value: 1 }, { text: '企业', value: 2 }] },
                { title: '产品', key: 'product', align: 'left', scopedSlots: { customRender: 'product' }, filters: [{ text: '名片', value: 1 }, { text: '其他', value: 2 }] },
                { title: '来源/名字', key: 'source', align: 'left', scopedSlots: { customRender: 'source' } },
                // { title: '名字', dataIndex: 'name', align: 'center' },
                { title: '微信/手机号', key: 'weixin', align: 'left', scopedSlots: { customRender: 'weixin' } },
                // { title: '手机号', dataIndex: 'mobile', align: 'center' },
                { title: '状态', key: 'status', align: 'left', scopedSlots: { customRender: 'status' } },
                // { title: '客户群', dataIndex: 'crowd', align: 'center' },
                { title: '备注', dataIndex: 'detail', align: 'left', width: 300 },
                { title: '操作', key: 'action', align: 'left', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
            data: {
                consult: '',
                source: 1,
                product: 1,
                type: 1,//1-个人 2-企业
                status: 1,
                name: '',
                weixin: '',
                mobile: '',
                detail: '',
                sort: 0
            },
            customData: {}
        }
    },
    created() {
        this.getList();
    },
    methods: {
        addCustom() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            this.data.consult = year + '-' + month + '-' + day
            this.customData = this.data;
            this.show = true;
            this.title = '添加客户';
            // this.save()
        },
        editCustom(detail) {
            console.log('detail', detail)
            this.title = '编辑客户';
            this.customData = detail;
            this.show = true;
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiCustomList({
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.search.keyword,
                status: this.search.status,
                start_time: this.search.data[0],
                end_time: this.search.data[1]
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        follow(detail) {//跟进
            console.log('detail', detail)
            apiFollow(detail).then(res=>{
                console.log('res',res)
                this.$message.success('操作成功');
                this.getList();
            })
        },
        loss(detail) {//流失
            detail.status = 3;
            this.customData = detail;
            this.save();
        },
        save() {//提交
            apiCustomSave(this.customData).then(res => {
                this.show = false;
                this.$message.success('操作成功');
                this.getList();
            })
        },

        change(pagination, filters, sorter) {
            console.log('filters', filters)
            this.pagination.current = pagination.current;
            this.getList();
        },
        searchAct() {
            this.pagination.current = 1;
            this.getList();
        },
        cancelAct() {
            this.pagination.current = 1;
            this.search = {
                keyword: '',
                data: ['', ''],
                status: 0
            };
            this.getList();
        }
    }
}
</script>

<style></style>
